<template>
  <p class="not">
    {{ $t('errors.page_not_found') }}
  </p>
</template>

<script>
export default {
  //
}
</script>

<style lang="scss">
  .not {
  text-align: center;
  font-size: 36px;
  margin-top: 20%;
}
</style>
